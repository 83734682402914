<template>
  <div class="work">
    <WorkPageIndex />
  </div>
</template>

<script>
import WorkPageIndex from "@/components/pages/work/PageIndex.vue";

export default {
  name: "home",
  title: "John Bauer | Work",
  components: {
    WorkPageIndex
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

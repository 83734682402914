<template>
  <div class="-ui-page-title">
    <section class="hero -page-title" :class="backgroundClass">
      <div class="hero-body" :class="{ '-is-overlapped': isOverlapped }">
        <div class="container">
          <h1 class="title is-family-secondary">
            {{ title }}
          </h1>
          <h2 class="subtitle">
            {{ subtitle }}
          </h2>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    title: String,
    subtitle: String,
    backgroundClass: String,
    isOverlapped: Boolean
  }
};
</script>

<style scoped lang="scss"></style>

<template>
  <div class="box has-background-warning">
    <slot> </slot>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>

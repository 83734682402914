<template>
  <div class="-case-studies-list">
    <!-- TODO: Abstract into components -->
    <section class="section">
      <div class="container">
        <SectionTitle title="Personal Projects" />
        <p class="subtitle is-5 is-size-6-mobile">
          I can't share everything that I've done professionally, but here you
          can explore a few projects that I've worked on in my spare time.
        </p>
        <hr />
        <br />
        <div class="columns reverse-columns">
          <div class="column">
            <h3 class="title is-size-3 is-size-4-touch is-family-secondary">
              Logsie
            </h3>
            <p class="subtitle is-italic is-5 is-size-6-mobile">
              Understand where you can afford to live, and explore hundreds of
              cities around the world.
            </p>
            <div class="buttons">
              <b-button
                icon-left="web"
                type="is-primary"
                tag="a"
                href="https://logsie.com"
                target="_blank"
                >Go to Website</b-button
              >
            </div>
          </div>
          <div class="column is-5">
            <figure class="image is-2by1">
              <img src="@/assets/img/thumbnails/personal-projects/08.jpg" />
            </figure>
          </div>
        </div>
        <hr />
        <div class="columns">
          <div class="column is-5">
            <figure class="image is-2by1">
              <img src="@/assets/img/thumbnails/personal-projects/09.jpg" />
            </figure>
          </div>
          <div class="column">
            <h3 class="title is-size-3 is-size-4-touch is-family-secondary">
              Startup Generator
            </h3>
            <p class="subtitle is-italic is-5 is-size-6-mobile">
              Translate words into startups.
            </p>
            <div class="buttons">
              <b-button
                icon-left="github-circle"
                type="is-github"
                tag="a"
                href="https://github.com/john-bauer/startup-generator"
                target="_blank"
                >Explore the Project</b-button
              >
              <b-button
                icon-left="rocket"
                type="is-heroku"
                tag="a"
                href="https://startup-generator.netlify.app/"
                target="_blank"
                >Live Demo</b-button
              >
            </div>
          </div>
        </div>
        <hr />
        <div class="columns reverse-columns">
          <div class="column">
            <h3 class="title is-size-3 is-size-4-touch is-family-secondary">
              Quokka
            </h3>
            <p class="subtitle is-italic is-5 is-size-6-mobile">
              A powerful, customizable, and 100% self-contained Sketch Design
              Kit, Based on
              <a href="https://material.io/design/">Material Design</a>.
            </p>
            <div class="buttons">
              <b-button
                icon-left="github-circle"
                type="is-github"
                tag="a"
                href="https://github.com/john-bauer/Quokka"
                target="_blank"
                >Download on Github</b-button
              >
            </div>
          </div>
          <div class="column is-5">
            <figure class="image is-2by1">
              <img src="@/assets/img/thumbnails/personal-projects/02.jpg" />
            </figure>
          </div>
        </div>
        <hr />
        <div class="columns">
          <div class="column is-5">
            <figure class="image is-2by1">
              <img src="@/assets/img/thumbnails/personal-projects/04.jpg" />
            </figure>
          </div>
          <div class="column">
            <h3 class="title is-size-3 is-size-4-touch is-family-secondary">
              JakeOffermann.com
            </h3>
            <p class="subtitle is-italic is-5 is-size-6-mobile">
              A minimalist vue.js website for a friend and local artist.
            </p>
            <div class="buttons">
              <b-button
                icon-left="web"
                type="is-primary"
                tag="a"
                href="https://jakeoffermann.com"
                target="_blank"
                >Go to Website</b-button
              >
            </div>
          </div>
        </div>
        <hr />
        <div class="columns reverse-columns">
          <div class="column">
            <h3 class="title is-size-3 is-size-4-touch is-family-secondary">
              Vue Charts
            </h3>
            <p class="subtitle is-italic is-5 is-size-6-mobile">
              An app that showcases the power of of
              <a href="https://vuejs.org/">Vue.js</a> and
              <a href="https://www.chartjs.org/">Chart.js</a>, all tied together
              with the
              <a href="https://vue-chartjs.org/">vue-chartjs</a> package.
            </p>
            <div class="buttons">
              <b-button
                icon-left="github-circle"
                type="is-github"
                tag="a"
                href="https://github.com/john-bauer/vue-charts"
                target="_blank"
                >Explore the Project</b-button
              >
              <b-button
                icon-left="rocket"
                type="is-heroku"
                tag="a"
                href="https://vue-charts-demo.netlify.com/"
                target="_blank"
                >Live Demo</b-button
              >
            </div>
          </div>
          <div class="column is-5">
            <figure class="image is-2by1">
              <img src="@/assets/img/thumbnails/personal-projects/05.jpg" />
            </figure>
          </div>
        </div>
        <hr />
        <div class="columns">
          <div class="column is-5">
            <figure class="image is-2by1">
              <img src="@/assets/img/thumbnails/personal-projects/01.jpg" />
            </figure>
          </div>
          <div class="column">
            <h3 class="title is-size-3 is-size-4-touch is-family-secondary">
              Sketch Material Colors
            </h3>
            <p class="subtitle is-italic is-5 is-size-6-mobile">
              A Sketch library that turns Google's
              <a
                href="https://material.io/design/color/the-color-system.html#color-usage-palettes"
                >Material Color System</a
              >
              into layer styles.
            </p>
            <div class="buttons">
              <b-button
                icon-left="github-circle"
                type="is-github"
                tag="a"
                href="https://github.com/john-bauer/sketch-material-colors"
                target="_blank"
                >Download on Github</b-button
              >
            </div>
          </div>
        </div>
        <hr />
        <div class="columns reverse-columns">
          <div class="column">
            <h3 class="title is-size-3 is-size-4-touch is-family-secondary">
              Example To-Do API
            </h3>
            <p class="subtitle is-italic is-5 is-size-6-mobile">
              An Express.js boilerplate built for application scaffolding,
              instructional use, and testing frontend environments.
              <a
                href="https://github.com/john-bauer/example-todo-api/blob/master/README.md"
                >Read the docs</a
              >
              for endpoints & instructions.
            </p>
            <div class="buttons">
              <b-button
                icon-left="github-circle"
                type="is-github"
                tag="a"
                href="https://github.com/john-bauer/example-todo-api"
                target="_blank"
                >Explore the Project</b-button
              >
              <b-button
                icon-left="rocket"
                type="is-heroku"
                tag="a"
                href="https://example-todo-api.herokuapp.com/"
                target="_blank"
                >Live Demo</b-button
              >
            </div>
          </div>
          <div class="column is-5">
            <figure class="image is-2by1">
              <img src="@/assets/img/thumbnails/personal-projects/03.jpg" />
            </figure>
          </div>
        </div>
        <hr />
        <div class="columns">
          <div class="column is-5">
            <figure class="image is-2by1">
              <img src="@/assets/img/thumbnails/personal-projects/07.jpg" />
            </figure>
          </div>
          <div class="column">
            <h3 class="title is-size-3 is-size-4-touch is-family-secondary">
              Coronavirus Tracker
            </h3>
            <p class="subtitle is-italic is-5 is-size-6-mobile">
              A data visualization of the
              <a
                href="https://systems.jhu.edu/research/public-health/ncov/"
                target="_blank"
                >John Hopkins Mapping 2019-nCoV research project</a
              >.
              <br />
              <br />
              <Alert>
                <p
                  class="subtitle is-size-6 -line-height has-text-weight-medium"
                >
                  Unfortunately, this project's API has been discontinued. I'm
                  currently looking for another data source.
                </p>
              </Alert>
            </p>
            <div class="buttons">
              <b-button
                icon-left="github-circle"
                type="is-github"
                tag="a"
                href="https://github.com/john-bauer/coronavirus-tracker"
                target="_blank"
                >Explore the Project</b-button
              >
            </div>
          </div>
        </div>
        <hr />
        <div class="columns reverse-columns">
          <div class="column">
            <h3 class="title is-size-3 is-size-4-touch is-family-secondary">
              News Flash
            </h3>
            <p class="subtitle is-italic is-5 is-size-6-mobile">
              Explore the latest, most relevant headlines from thousands of
              sources.
            </p>
            <Alert>
              <p class="subtitle is-size-6 -line-height has-text-weight-medium">
                Due to changes in News API's free plan, this project is
                no-longer maintained, but feel free to browse the source code.
              </p>
            </Alert>
            <div class="buttons">
              <b-button
                icon-left="github-circle"
                type="is-github"
                tag="a"
                href="https://github.com/john-bauer/newsflash"
                target="_blank"
                >Explore the Project</b-button
              >
            </div>
          </div>
          <div class="column is-5">
            <figure class="image is-2by1">
              <img src="@/assets/img/thumbnails/personal-projects/06.jpg" />
            </figure>
          </div>
        </div>
        <hr />
      </div>
    </section>
  </div>
</template>
<script>
import SectionTitle from "@/components/ui/SectionTitle.vue";
import Alert from "@/components/ui/Alert.vue";

export default {
  name: "CaseStudiesList",
  components: {
    SectionTitle,
    Alert
  }
};
</script>

<style scoped lang="scss">
@media (max-width: 767px) {
  .reverse-columns {
    flex-direction: column-reverse;
    display: flex;
  }
}

.-line-height {
  line-height: 1.6;
}
</style>

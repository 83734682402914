<template>
  <div class="-work-page-index">
    <PageTitle
      title="My Work"
      subtitle="A short breakdown of my background and projects that I've been involved in."
      backgroundClass="has-background-white-bis"
    />
    <OtherProjects />
    <hr />
    <CaseStudiesList />
  </div>
</template>

<script>
import PageTitle from "@/components/ui/PageTitle.vue";
import OtherProjects from "@/components/pages/work/OtherProjects.vue";
import CaseStudiesList from "@/components/pages/work/case-studies/CaseStudiesList.vue";
export default {
  name: "WorkPageIndex",
  components: {
    PageTitle,
    OtherProjects,
    CaseStudiesList
  }
};
</script>

<style scoped lang="scss"></style>

<template>
  <div class="-other-projects">
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <h1
            class="title has-text-centered is-size-5 is-size-6-mobile is-uppercase"
          >
            Projects I've Been a Part Of:
          </h1>
          <div class="columns is-mobile">
            <div class="column is-4" v-for="item in row1" :key="item.text">
              <figure class="image">
                <a :href="item.url" target="_blank">
                  <img :src="item.image" class="-brand-logo" ref="brandLogo" />
                </a>
              </figure>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column is-4" v-for="item in row2" :key="item.text">
              <figure class="image">
                <a :href="item.url" target="_blank">
                  <img :src="item.image" class="-brand-logo" ref="brandLogo" />
                </a>
              </figure>
            </div>
          </div>
          <p class="subtitle is-size-6 is-size-7-touch has-text-centered">
            For more detailed case-studies, work samples, and descriptions of my
            involvement, please
            <router-link to="/contact">contact me</router-link>.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "OtherProjects",
  data() {
    return {
      row1: [
        {
          text: "CTFN",
          image: require("@/assets/img/logos/other-projects/01.png"),
          url: "https://ctfn.news/"
        },
        {
          text: "Cometa",
          image: require("@/assets/img/logos/other-projects/02.png"),
          url: "https://cometa.group/"
        },
        {
          text: "CrashCode",
          image: require("@/assets/img/logos/other-projects/03.png"),
          url: "https://crashcode.com/"
        }
      ],
      row2: [
        {
          text: "Blue Laurel",
          image: require("@/assets/img/logos/other-projects/04.png"),
          url: "https://www.mybluelaurel.com/"
        },
        {
          text: "https://www.crowdflik.com/",
          image: require("@/assets/img/logos/other-projects/05.png"),
          url: "https://cometa.group/"
        },
        {
          text: "https://encaptiv.com/",
          image: require("@/assets/img/logos/other-projects/06.png")
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.-brand-logo {
  max-width: 200px;
  margin: 0 auto;
}
</style>
